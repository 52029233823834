<template>
  <div>
    <el-card style="min-height: 45rem">
      <div>
        <el-row :gutter="20">
          <el-col :span="5">
            <div class="flex-def flex-cCenter">
              <div class="y-desc" style="font-weight: 600;white-space: nowrap;width: 5rem">用户信息</div>
              <el-input size="mini" v-model="searchForm.info" placeholder="用户昵称/ID/手机号"></el-input>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="flex-def flex-cCenter">
              <div class="y-desc" style="font-weight: 600;white-space: nowrap;width: 5rem">提现方式</div>
              <el-select size="mini" v-model="searchForm.ua_type">
                <el-option :value="0" label="全部"></el-option>
                <el-option :value="1" label="微信公众号"></el-option>
                <el-option :value="2" label="支付宝"></el-option>
                <el-option :value="3" label="银行卡"></el-option>
                <el-option :value="4" label="微信小程序"></el-option>
                <el-option :value="5" label="余额"></el-option>
                <el-option :value="6" label="借权微信公众号"></el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="flex-def flex-cCenter">
              <div class="y-desc" style="font-weight: 600;white-space: nowrap;width: 5rem">提现状态</div>
              <el-select size="mini" v-model="searchForm.cash_status">
                <el-option :value="0" label="全部"></el-option>
<!--                <el-option :value="1" label="待打款"></el-option>-->
                <el-option :value="2" label="已打款"></el-option>
                <el-option :value="3" label="已驳回"></el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="flex-def flex-cCenter">
              <div class="y-desc" style="font-weight: 600;white-space: nowrap;width: 5rem">流水单号</div>
              <el-input size="mini" v-model="searchForm.order_no" placeholder="请输入系统流水单号"></el-input>
            </div>
          </el-col>
          <el-col :span="10">
            <div class="flex-def flex-cCenter" style="margin: .5rem 0">
              <div class="y-desc" style="font-weight: 600;white-space: nowrap;width: 7rem">提现申请时间</div>
              <el-date-picker
                  size="mini"
                  style="width: 100%"
                  v-model="searchForm.time"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="申请时间范围开始日期"
                  end-placeholder="申请时间范围结束日期">
              </el-date-picker>
            </div>
          </el-col>
          <el-col :span="14">
            <div style="margin: .5rem 0" class="flex-def flex-zBetween">
              <el-button-group>
                <el-button size="mini" @click="searchGo" type="primary">搜索</el-button>
                <el-button size="mini" @click="searchReset">重置</el-button>
              </el-button-group>
              <el-button @click="excelExport" size="mini" type="danger">筛选导出</el-button>
            </div>
          </el-col>
        </el-row>
      </div>
      <el-table row-key="id" :data="list" stripe>
        <el-table-column width="80" prop="id" label="编号"></el-table-column>
        <el-table-column width="200" label="用户信息">
          <template #default="s">
            <div v-if="s.row.user" class="flex-def flex-cCenter">
              <img :src="s.row.user.avatar | tomedia" style="width: 4rem;height: 4rem;border-radius: 3rem" alt="">
              <div style="flex: 1;margin-left: .5rem">
                <div>编号：{{s.row.user.id}}</div>
                <div>昵称：{{s.row.user.nickname}}</div>
                <div>手机：{{s.row.user.phone}}</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="fee" width="140" label="提现金额">
          <template #default="s">
            <div ><span class="y-desc">提现金额：</span>{{s.row.fee}}</div>
            <div ><span class="y-desc">手续费金额：</span>{{s.row.rate_fee}}</div>
            <div ><span class="y-desc">实际到账：</span>{{s.row.real_fee}}</div>
          </template>
        </el-table-column>
        <el-table-column width="200" label="实名信息">
          <template #default="s">
            <div v-if="s.row.user">
              <div>姓名：{{s.row.user.ac_name}}</div>
              <div>身份证：{{s.row.user.ac_id_card}}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="get_info_log" width="200" label="提现方式">
          <template #default="s">
            <div v-if="parseObj(s.row.get_info_log).id > 0">
              <div>{{parseObj(s.row.get_info_log).bank_name}}</div>
              <div>姓名：{{parseObj(s.row.get_info_log).name}}</div>
              <div>账号：{{parseObj(s.row.get_info_log).account}}</div>
            </div>
            <div v-else>余额提现</div>
          </template>
        </el-table-column>
        <el-table-column prop="created_at" width="180" label="申请时间/打款时间">
          <template #default="s">
            <div>{{s.row.created_at | date}}</div>
            <div>{{s.row.pay_time | date}}</div>
          </template>
        </el-table-column>
        <el-table-column label="状态">
          <template #default="s">
            <el-tag :type="statusFormat(s.row).type" effect="dark">{{statusFormat(s.row).title}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="系统流水单号/外部流水单号" width="200">
          <template #default="s">
            <div style="font-weight: 600;color: #64adf2">
              <div>{{s.row.order_no}}</div>
              <div>{{s.row.payment_no ? s.row.payment_no : '无外部流水单号'}}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="fail_log" label="异常信息" show-overflow-tooltip></el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
          </el-pagination>
        </template>
      </el-table>
    </el-card>
  </div>
</template>

<script>

export default {
  name: "manage",
  data() {
    return {
      list: [],
      page: 1,
      total: 0,
      size: 15,
      searchForm:{
        info:"",
        ua_type:0,
        cash_status:0,
        order_no:"",
        time:[],
      },
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    statusFormat(row){
      let obj = {
        title:"",
        type:"",
      }
      switch (parseInt(row.status)){
        case 1:
          obj.title = "待打款";
          break;
        case 2:
          obj.title = "已打款";
          obj.type = "success";
          break;
        case 3:
          obj.title = "已驳回";
          obj.type = "danger"
          break;
      }
      return obj
    },
    searchGo(){
      this.page = 1;
      this.list.length =0;
      this.load();
    },
    excelExport(){
      this.$u.api.user.cashSearchExport({
        status:"log",
        ...this.searchForm,
      }).then(res=>{
        window.open(res,"_blank")
      })
    },
    searchReset(){
      this.searchForm = {
        info:"",
        ua_type:0,
        cash_status:0,
        order_no:"",
        time:[],
      }
      this.page = 1;
      this.list = [];
      this.searchGo()
    },
    parseObj(log){
      if (!log)return {};
      return JSON.parse(log);
    },
    load() {
      this.$u.api.user.cashSearch({
        page:this.page,
        status:"log",
        ...this.searchForm,
      }).then(res=>{
        this.list = res.list;
        this.total = res.total;
        this.size = res.size;
      })
    },
    pageChange(e) {
      this.page = e;
      this.load();
    }
  }
}
</script>

<style scoped>

</style>